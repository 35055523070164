var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-timeline',{attrs:{"dense":"","clipped":""}},_vm._l((_vm.items),function(item){return _c('v-timeline-item',{key:item.id,attrs:{"large":"","color":_vm.currencyColorMap.get(item.requested.code),"icon":_vm.$helpers.getCurrencyIcon(item.requested.code)}},[_c('v-list',{attrs:{"dense":"","two-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-2"},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$t('hr.travel.imprest_message', [
                  `${item.employee.firstName} ${item.employee.lastName}`,
                  _vm.$helpers.formattedDateTime(item.timestamp),
                  _vm.$helpers.getConvertCurrency(item.requested)
                ])
              )}})]),_c('v-list-item-subtitle',[_c('request-status-label',{staticClass:"mx-0 subtitle-2",attrs:{"status":item.requestStatus}})],1)],1)],1)],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }